.App {
  min-height: 100vh;
  text-align: center;
}

html {
  background-color: #282c34;
}

.button {
  position: absolute;
  left: calc(50vw - 150px);
}

.TrafficButton {
  top: calc(50vh - 135px);
}

.DomainButton {
  top: calc(50vh - 50px);
}

.AttackButton {
  top: calc(50vh + 35px);
}

.back {
  position:absolute;
  top:calc(50vh - 300px);
  left:calc(50vw - 550px);
  cursor:pointer;
  display:none;
}

.tChart, .aChart, .pChart {
  display:none;
  justify-content: center;
  align-items: center;
  height:100vh;
}